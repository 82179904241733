<template>
  <div
    :class="{
      'justify-center': true,
      'main-grid': !$vuetify.breakpoint.mobile,
      'main-grid-mobile': $vuetify.breakpoint.mobile,
      'text-center': true,
      'mb-5': true
    }"
  >
    <HowToPlay :i18n_instructions_key="'wordSearchInstruction'" />
    <ul class="app-grid list">
      <svg
        id="appIllustrations"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      ></svg>
      <li
        v-for="(letter, idx) in letters"
        class="grid-letter"
        :style="{ width: `${cellWidth}%`, fontSize: `${letterFontSize}px` }"
        :key="letter + idx"
        :id="'letter' + idx"
      >
        {{ letter }}
      </li>
    </ul>
    <div v-if="reset" class="text-center">
      <v-btn @click="resetGrid()" color="primary">
        {{ $t('Play Again') }}
      </v-btn>
    </div>

    <ul class="app-words list">
      <li
        v-for="(word, i) in wordsFound"
        :class="showWords && word.done ? 'word-done grid-word' : 'grid-word'"
        :key="word + i"
      >
        {{ word.word }}
      </li>
    </ul>

    <div v-if="wordsFound.length >= 5 && !reset" class="text-center">
      <v-btn @click="showWords = !showWords" color="primary">
        {{ $t('Show words') }}
      </v-btn>
    </div>
    <WordHelpDialog
      v-if="showTranslationOf != undefined"
      :visible="showTranslationOf !== undefined"
      :word="showTranslationOf"
      @close="showTranslationOf = undefined"
    ></WordHelpDialog>
    <GoBackButton />
  </div>
</template>
<script>
import $ from 'jquery'

import puzzler from '../util/wordsearch/puzzler'
import Utility from '../util/wordsearch/utility'
import HowToPlay from './HowToPlay.vue'
import Utils from '../util/Utils'
import WordHelpDialog from './WordHelpDialog'
import GoBackButton from './GoBackButton'

import { mapGetters } from 'vuex'

let p1, p2, lp1, lp2, cp2, line, paper, mouseDown

export default {
  components: { HowToPlay, WordHelpDialog, GoBackButton },
  data: () => ({
    letters: '',
    words: [],
    selword: '',
    selectedGridSize: 10,
    gridConfig: {
      10: {
        desktop: { width: 9.6, letterFontSize: 20, strokeWidth: 30 },
        mobile: { width: 9.6, letterFontSize: 17, strokeWidth: 27 }
      },
      15: {
        desktop: { width: 6.6, letterFontSize: 12, strokeWidth: 15 },
        mobile: { width: 6.6, letterFontSize: 12, strokeWidth: 15 }
      }
    },
    wordsSize: 10,
    wordSelectionColor: [
      '#e5a2a2',
      '#82e782',
      'yellow',
      '#89c5c7',
      'violet',
      '#f3c777',
      '#c9b9bc',
      '#4df9d7',
      '#c6c5ef',
      '#d2db9b'
    ],
    reset: false,
    showWords: false,
    showTranslationOf: undefined
  }),
  mounted() {
    this.resetGrid()
  },
  computed: {
    ...mapGetters(['wordSearchList', 'wordList']),

    wordsFound() {
      return this.words.filter((w) => w.done || this.showWords)
    },

    wordsNotFound() {
      return this.words.filter((w) => !w.done)
    },

    wordsForSearch() {
      return Object.keys(this.wordList.level2).filter(
        (w) => Utils.gujaratiWordSeperator(w).length >= 3
      )
    },
    screenType() {
      if (this.$vuetify.breakpoint.mobile) {
        return 'mobile'
      } else {
        return 'desktop'
      }
    },
    cellWidth() {
      return this.gridConfig[this.selectedGridSize][this.screenType].width
    },
    strokeWidth() {
      return this.gridConfig[this.selectedGridSize][this.screenType].strokeWidth
    },
    letterFontSize() {
      return this.gridConfig[this.selectedGridSize][this.screenType]
        .letterFontSize
    }
  },
  methods: {
    resetGrid() {
      this.words = []
      this.letters = ''
      this.selword = ''
      this.reset = false
      this.showTranslationOf = undefined
      this.showWords = false
      const indexesOfwordsAdded = []
      p1 = ''
      p2 = ''
      lp1 = ''
      lp2 = ''
      cp2 = ''
      line = ''
      paper = ''
      $('#appIllustrations line').remove()

      for (let i = 0; i < this.wordsSize; i++) {
        let newRandomIndex = Math.floor(
          Math.random() * this.wordsForSearch.length
        )
        while (
          indexesOfwordsAdded.findIndex((i) => i === newRandomIndex) >= 0
        ) {
          newRandomIndex = Math.floor(
            Math.random() * this.wordsForSearch.length
          )
        }

        indexesOfwordsAdded.push(newRandomIndex)

        this.words.push({
          word: this.wordsForSearch[newRandomIndex],
          done: false
        })
      }

      let puzzle = puzzler.generatePuzzle(
        this.words.map((word) => word.word),
        this.selectedGridSize,
        this.selectedGridSize
      )
      while (!puzzle) {
        puzzle = puzzler.generatePuzzle(
          this.words.map((word) => word.word),
          this.selectedGridSize,
          this.selectedGridSize
        )
      }
      var lttrs = []
      puzzle.forEach((row) => {
        row.forEach((l) => lttrs.push(l))
      })
      this.letters = lttrs
      this.registerEvents()
    },
    onWordMatch(idx) {
      const mword = this.words[idx]
      mword.done = true
      this.showTranslationOf = {
        guj: mword.word,
        ...this.wordList.level2[mword.word]
      }
      if (this.words.filter((w) => w.done).length === this.wordsSize) {
        this.reset = true
        this.showWords = false
        Utils.dropConfetti(this.$confetti)
      }
    },

    getSelectedWord(data) {
      let word = ''
      if (data.indices) {
        data.indices.forEach((idx) => {
          word += this.letters[idx]
        })
      }
      return word
    },
    registerEvents() {
      var paper = Snap('#appIllustrations')
      const _this = this // Vue context object so that it can be used in event handlers below

      var p1, p2, lp1, lp2, cp2, line, matchedIndex
      $('.app-grid').on('mousedown touchstart', '.grid-letter', function (ev) {
        mouseDown = true
        var idx = $(this).index('.grid-letter')

        p1 = $(this).position()

        var touch =
          ev.originalEvent &&
          ev.originalEvent.touches &&
          ev.originalEvent.touches[0]

        ev = touch || ev
        if (touch) {
          var temp = document.elementFromPoint(touch.clientX, touch.clientY)
          if (temp && temp.id) {
            const sel = `#${temp.id}`
            p1 = $(sel).position()
            idx = $(sel).index() - 1
          }
        }

        lp1 = {
          y: Math.floor(idx / _this.selectedGridSize),
          x: idx % _this.selectedGridSize
        }
        line = paper.line(
          p1.left + _this.letterFontSize,
          p1.top + _this.letterFontSize,
          p1.left + _this.letterFontSize,
          p1.top + _this.letterFontSize
        )

        const lineColor =
          _this.wordSelectionColor[
            _this.words.filter((w) => w.done).length %
              _this.wordSelectionColor.length
          ]
        line.attr({
          class: 'line-1',
          style: `stroke: ${lineColor}; stroke-width: ${_this.strokeWidth}`
        })
      })
      $('.app-grid').on('mouseenter touchmove', '.grid-letter', function (ev) {
        if (mouseDown) {
          if (ev.type === 'touchmove') {
            ev.preventDefault()
          }
          var p2 = $(this).position()
          var idx = $(this).index('.grid-letter')

          var touch =
            ev.originalEvent &&
            ev.originalEvent.touches &&
            ev.originalEvent.touches[0]

          ev = touch || ev
          if (touch) {
            var temp = document.elementFromPoint(touch.clientX, touch.clientY)
            if (temp && temp.id) {
              const sel = `#${temp.id}`
              p2 = $(sel).position()
              idx = $(sel).index() - 1
            }
          }

          cp2 = {
            y: Math.floor(idx / _this.selectedGridSize),
            x: idx % _this.selectedGridSize
          }
          if (
            Utility.isValidMove(
              Utility.Math.getAngle(lp1.x, lp1.y, cp2.x, cp2.y)
            )
          ) {
            line.attr({
              x2: p2.left + _this.letterFontSize,
              y2: p2.top + _this.letterFontSize
            })
            const selWord = _this.getSelectedWord(
              Utility.lettersBetween(
                lp1.x,
                lp1.y,
                cp2.x,
                cp2.y,
                _this.selectedGridSize
              )
            )
            _this.selWord = selWord
          } else {
            _this.selword = ''
          }
        }
      })
      $('.app-grid').on('mouseup touchend', '.grid-letter', function (ev) {
        mouseDown = false
        let idx = $(this).index('.grid-letter')

        var touch =
          ev.originalEvent &&
          ev.originalEvent.changedTouches &&
          ev.originalEvent.changedTouches[0]

        ev = touch || ev
        if (touch) {
          var temp = document.elementFromPoint(touch.clientX, touch.clientY)
          if (temp && temp.id) {
            const sel = `#${temp.id}`
            idx = $(sel).index() - 1
          }
        }

        lp2 = {
          y: Math.floor(idx / _this.selectedGridSize),
          x: idx % _this.selectedGridSize
        }
        if (
          Utility.isValidMove(Utility.Math.getAngle(lp1.x, lp1.y, cp2.x, cp2.y))
        ) {
          const selWord = _this.getSelectedWord(
            Utility.lettersBetween(
              lp1.x,
              lp1.y,
              lp2.x,
              lp2.y,
              _this.selectedGridSize
            )
          )
          matchedIndex = _this.words.findIndex((word) => {
            return !word.done && word.word === selWord
          })

          if (matchedIndex === -1) {
            $(line.node).fadeOut('slow', function () {
              $(this).remove()
              mouseDown = false
            })
          } else {
            _this.onWordMatch(matchedIndex)
          }
          _this.selWord = ''
        } else {
          _this.selWord = ''
          $(line.node).fadeOut('slow', function () {
            $(this).remove()
            mouseDown = false
          })
        }
      })

      $('.v-main__wrap').on('mouseup touchend', function (ev) {
        if (mouseDown) {
          $(line.node).fadeOut('slow', function () {
            $(this).remove()
          })
          _this.selWord = ''
        }
      })
    }
  }
}
</script>
<style scoped>
.main-grid {
  min-height: 400px;
  max-width: 420px;
  min-width: 320px;
  width: 30%;
  margin: 5px auto;
}
.main-grid-mobile {
  min-height: 400px;
  margin: 5px auto;
}
.app-grid {
  width: 100%;
  min-height: 100px;
  padding: 5px;
  background-color: #fff;
  position: relative;
}
.list {
  list-style-type: none;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.grid-letter {
  box-sizing: border-box;
  position: relative;
  list-style-type: none;
  transition: all 0.3s ease;
  display: inline-block;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.9);
  text-transform: uppercase;
  text-shadow: 1px 1px 2px hsl(0deg 0% 59% / 50%);
  border: 0.1px solid #f3d9d9;
  cursor: pointer;
}

#appIllustrations {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

#appIllustrations >>> .line-1 {
  stroke-linecap: round;
  stroke-opacity: 0.5;
}
:not(svg) {
  transform-origin: 0px 0px;
}

.app-words {
  padding: 10px 15px;
  margin: 0;
  text-align: center;
}
.app-words >>> li {
  display: inline-block;
  min-width: 70px;
  text-align: center;
  font-size: 10;
  margin: 5px 10px;
  color: grey;
}
.word-done {
  opacity: 0.7;
  text-decoration: line-through;
  text-decoration-color: var(--v-primary-base);
}
</style>
