var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'justify-center': true,
    'main-grid': !_vm.$vuetify.breakpoint.mobile,
    'main-grid-mobile': _vm.$vuetify.breakpoint.mobile,
    'text-center': true,
    'mb-5': true
  }},[_c('HowToPlay',{attrs:{"i18n_instructions_key":'wordSearchInstruction'}}),_c('ul',{staticClass:"app-grid list"},[_c('svg',{attrs:{"id":"appIllustrations","version":"1.1","xmlns":"http://www.w3.org/2000/svg"}}),_vm._l((_vm.letters),function(letter,idx){return _c('li',{key:letter + idx,staticClass:"grid-letter",style:({ width: `${_vm.cellWidth}%`, fontSize: `${_vm.letterFontSize}px` }),attrs:{"id":'letter' + idx}},[_vm._v(" "+_vm._s(letter)+" ")])})],2),(_vm.reset)?_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.resetGrid()}}},[_vm._v(" "+_vm._s(_vm.$t('Play Again'))+" ")])],1):_vm._e(),_c('ul',{staticClass:"app-words list"},_vm._l((_vm.wordsFound),function(word,i){return _c('li',{key:word + i,class:_vm.showWords && word.done ? 'word-done grid-word' : 'grid-word'},[_vm._v(" "+_vm._s(word.word)+" ")])}),0),(_vm.wordsFound.length >= 5 && !_vm.reset)?_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showWords = !_vm.showWords}}},[_vm._v(" "+_vm._s(_vm.$t('Show words'))+" ")])],1):_vm._e(),(_vm.showTranslationOf != undefined)?_c('WordHelpDialog',{attrs:{"visible":_vm.showTranslationOf !== undefined,"word":_vm.showTranslationOf},on:{"close":function($event){_vm.showTranslationOf = undefined}}}):_vm._e(),_c('GoBackButton')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }